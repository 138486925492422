import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom';

export default function Login() {
    console.log('login screen called.');
    return(
        <>
            <body>
                {/* <!-- Content --> */}
                <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner py-6">
                    {/* <!-- Login --> */}
                    <div className="card">
                        <div className="card-body">
                        {/* <!-- Logo --> */}
                        <div className="app-brand justify-content-center mb-6">
                            <a href="index.html" className="app-brand-link">
                            <img src="assets/workx-logo.png" alt="WorkX Logo" width="250"/>

                            </a>
                        </div>
                        {/* <!-- /Logo --> */}
                        <h4 className="mb-1">Login</h4>
                        <p className="mb-6">Please sign-in to your account and start the adventure</p>

                        <form id="formAuthentication" className="mb-4" action="index.html" method="GET">
                            <div className="mb-6">
                            <label for="email" className="form-label">Email or Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email-username"
                                placeholder="Enter your email or username"
                                autofocus />
                            </div>
                            <div className="mb-6 form-password-toggle">
                            <label className="form-label" for="password">Password</label>
                            <div className="input-group input-group-merge">
                                <input
                                type="password"
                                id="password"
                                className="form-control"
                                name="password"
                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                aria-describedby="password" />
                                <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                            </div>
                            </div>
                            <div className="my-8">
                            <div className="d-flex justify-content-between">
                                <div className="form-check mb-0 ms-2">
                                <input className="form-check-input" type="checkbox" id="remember-me" />
                                <label className="form-check-label" for="remember-me"> Remember Me </label>
                                </div>
                                <a href="./innerpanel/panel/auth-forgot-password-basic.html">
                                <p className="mb-0">Forgot Password?</p>
                                </a>
                            </div>
                            </div>
                            <div className="mb-6">
                                <Link to="/dashboard" className="btn btn-primary d-grid w-100">Login</Link>
                            </div>
                        </form>

                        <p className="text-center">
                            <span>New on our platform?</span>
                            <a href="./innerpanel/panel/auth-register-basic.html">
                            <span>Create an account</span>
                            </a>
                        </p>

                        <div className="divider my-6">
                            <div className="divider-text">or</div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <a href="#!" className="btn btn-sm btn-icon rounded-pill btn-text-facebook me-1_5">
                            <i className="tf-icons ti ti-brand-facebook-filled"></i>
                            </a>

                            <a href="#!" className="btn btn-sm btn-icon rounded-pill btn-text-twitter me-1_5">
                            <i className="tf-icons ti ti-brand-twitter-filled"></i>
                            </a>

                        {/* <!--  <a href="#!" className="btn btn-sm btn-icon rounded-pill btn-text-github me-1_5">
                            <i className="tf-icons ti ti-brand-github-filled"></i>
                            </a> --> */}

                            <a href="#!" className="btn btn-sm btn-icon rounded-pill btn-text-google-plus">
                            <i className="tf-icons ti ti-brand-google-filled"></i>
                            </a>
                        </div>
                        </div>
                    </div>
                    {/* <!-- /Register --> */}
                    </div>
                </div>
                </div>
                
                {/* <!-- Core JS --> */}
                {/* <!-- build:js assets/vendor/js/core.js --> */}

                <script src="../../assets/vendor/libs/jquery/jquery.js"></script>
                <script src="../../assets/vendor/libs/popper/popper.js"></script>
                <script src="../../assets/vendor/js/bootstrap.js"></script>
                <script src="../../assets/vendor/libs/node-waves/node-waves.js"></script>
                <script src="../../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
                <script src="../../assets/vendor/libs/hammer/hammer.js"></script>
                <script src="../../assets/vendor/libs/i18n/i18n.js"></script>
                <script src="../../assets/vendor/libs/typeahead-js/typeahead.js"></script>
                <script src="../../assets/vendor/js/menu.js"></script>

                {/* <!-- endbuild --> */}

                {/* <!-- Vendors JS --> */}
                <script src="../../assets/vendor/libs/apex-charts/apexcharts.js"></script>
                <script src="../../assets/vendor/libs/swiper/swiper.js"></script>
                <script src="../../assets/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>

                {/* <!-- Main JS --> */}
                <script src="../../assets/js/main.js"></script>

                {/* <!-- Page JS --> */}
                <script src="../../assets/js/dashboards-analytics.js"></script>
                <script src="../../assets/js/app-ecommerce-dashboard.js"></script>
            </body>
        </>
    );
}
