import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './layout';
import AllCustomers from "./customers-module/pages/all-customers";
import Sites from "./customers-module/pages/sites";
import Dashboard from "./dashboard-module/pages/dashboard";
import AddProduct from "./inventory-module/pages/add-product";
import CategoryList from "./inventory-module/pages/category-list";
import ProductList from "./inventory-module/pages/product-list";
import StoreDetails from "./inventory-module/pages/store-details";
import StoreList from "./inventory-module/pages/store-list";
import FieldDashboard from "./jobs-module/pages/field-dashboard";
import FieldTracking from "./jobs-module/pages/field-tracking";
import JobCalndar from "./jobs-module/pages/job-calendar";
import JobKanban from "./jobs-module/pages/job-kanban";
import Contacts from "./leads-module/pages/contacts";
import Leads from "./leads-module/pages/leads";
import PaymentsRecieved from "./payments-module/pages/payments-received";
import Quote from "./quote-module/pages/quote";
import Settings from "./settings-module/pages/settings";
import Checkout from "./subscription-module/pages/checkout";
import Pricing from "./subscription-module/pages/pricing";
import Tickets from "./support-module/pages/tickets";
import HelpCenterDetails from "./support-module/pages/help-center-details";
import HelpCenter from "./support-module/pages/help-center";
import FAQ from "./support-module/pages/faq";
import AccountSecurity from "./user-module/pages/account-security";
import BillingPlans from "./user-module/pages/billing-plans";
import Roles from "./user-module/pages/roles";
import Teams from "./user-module/pages/teams";
import UserList from "./user-module/pages/user-list";
import UserProfile from "./user-module/pages/user-profile";
import EmailVerify from "./login-module/pages/email-verify";
import ForgotPassword from "./login-module/pages/forgot-password";
import ResetPassword from "./login-module/pages/reset-password";
import TwoStepVerification from "./login-module/pages/two-step-verification";
import Login from "./login-module/pages/login";
import React from 'react';

function App() {
    return (
        <>
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="/en/n" element={<Login/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/email-verify" element={<EmailVerify/>} />
                    <Route path="/forgot-password" element={<ForgotPassword/>} />
                    <Route path="/reset-password" element={<ResetPassword/>} />
                    <Route path="/two-step-verification" element={<TwoStepVerification/>} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/all-customers" element={<AllCustomers/>} />
                    <Route path="/sites" element={<Sites/>} />
                    <Route path="/add-product" element={<AddProduct/>} />
                    <Route path="/category-list" element={<CategoryList/>} />
                    <Route path="/product-list" element={<ProductList/>} />
                    <Route path="/store-details" element={<StoreDetails/>} />
                    <Route path="/store-list" element={<StoreList/>} />
                    <Route path="/field-dashboard" element={<FieldDashboard/>} />
                    <Route path="/field-tracking" element={<FieldTracking/>} />
                    <Route path="/job-calendar" element={<JobCalndar/>} />
                    <Route path="/job-kanban" element={<JobKanban/>} />
                    <Route path="/contacts" element={<Contacts/>} />
                    <Route path="/leads" element={<Leads/>} />
                    <Route path="/payments-recieved" element={<PaymentsRecieved/>} />
                    <Route path="/quote" element={<Quote/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/checkout" element={<Checkout/>} />
                    <Route path="/pricing" element={<Pricing/>} />
                    <Route path="/faq" element={<FAQ/>} />
                    <Route path="/help-center" element={<HelpCenter/>} />
                    <Route path="/help-center-details" element={<HelpCenterDetails/>} />
                    <Route path="/tickets" element={<Tickets/>} />
                    <Route path="/account-security" element={<AccountSecurity/>} />
                    <Route path="/billing-plans" element={<BillingPlans/>} />
                    <Route path="/roles" element={<Roles/>} />
                    <Route path="/teams" element={<Teams/>} />
                    <Route path="/user-list" element={<UserList/>} />
                    <Route path="/user-profile" element={<UserProfile/>} />

                    
                </Routes>
            </Router>
        </React.StrictMode>
        </>
    );
}

export default App;