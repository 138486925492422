import React from 'react';

export default function UserMenuComponent() {
    return (
        <>
            <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                    class="nav-link dropdown-toggle hide-arrow p-0"
                    href=" #!"
                    data-bs-toggle="dropdown">
                    <div class="avatar avatar-online">
                      <img src="../assets/img/avatars/1.png" alt="" class="rounded-circle" />
                    </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                        <a class="dropdown-item mt-0" href="pages-account-settings-account.html">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-2">
                                    <div class="avatar avatar-online">
                                        <img src="../assets/img/avatars/1.png" alt="" class="rounded-circle" />
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <h6 class="mb-0">John Doe</h6>
                                    <small class="text-muted">Admin</small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                      <div class="dropdown-divider my-1 mx-n2"></div>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/user-profile">
                            <i class="ti ti-user me-3 ti-md"></i><span class="align-middle">My Profile</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/account-security">
                            <i class="ti-md ti ti-lock me-3"></i><span class="align-middle">Account Security</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/billing-plans">
                            <span class="d-flex align-items-center align-middle">
                                <i class="flex-shrink-0 ti ti-file-dollar me-3 ti-md"></i
                            ><span class="flex-grow-1 align-middle">Billing</span>
                            <span class="flex-shrink-0 badge bg-danger d-flex align-items-center justify-content-center"
                                >4</span
                            >
                            </span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/pricing">
                            <i class="ti ti-currency-dollar me-3 ti-md"></i><span class="align-middle">Pricing</span>
                        </a>
                    </li>
                    <li>
                        <div class="dropdown-divider my-1 mx-n2"></div>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/faq">
                            <i class="ti ti-question-mark me-3 ti-md"></i><span class="align-middle">FAQ</span>
                        </a>
                    </li>    
                    <li>
                        <a class="dropdown-item" href="/help-center">
                            <i class="ti ti-lifebuoy me-3 ti-md"></i><span class="align-middle">Help Center</span>
                        </a>
                    </li>   
                    <li>
                        <a class="dropdown-item" href="/tickets">
                            <i class="ti ti-messages me-3 ti-md"></i><span class="align-middle">Tickets</span>
                        </a>
                    </li>
                    <li>
                        <div class="d-grid px-2 pt-2 pb-1">
                            <a class="btn btn-sm btn-danger d-flex" href="auth-login-cover.html" target="_blank">
                            <small class="align-middle">Logout</small>
                            <i class="ti ti-logout ms-2 ti-14px"></i>
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
        </>
    );
}