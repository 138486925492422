import React from 'react';

import { Grid2 } from '@mui/material/';
import DistributedBarChartOrder from '../../../components/main-dashoard/DistributedBarChartOrder';
import LineAreaYearlySalesChart from '../../../components/main-dashoard/LineAreaYearlySalesChart';

import VerticalMenuComponent from '../../../components/VerticalMenuComponent';
import TopMenuComponent from '../../../components/TopMenuComponent';


export default function MainDashboard() {

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <VerticalMenuComponent/>
                    <div class="layout-page">
                        <TopMenuComponent/>
                        <Grid2 container spacing={6}>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <DistributedBarChartOrder />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <LineAreaYearlySalesChart />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <DistributedBarChartOrder />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <LineAreaYearlySalesChart />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <DistributedBarChartOrder />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <LineAreaYearlySalesChart />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <DistributedBarChartOrder />
                            </Grid2>
                            <Grid2 item xs={12} sm={6} md={4} lg={2}>
                                <LineAreaYearlySalesChart />
                            </Grid2>
                        </Grid2>
                        
                    </div>
                </div>

                {/* <!-- Overlay --> */}
                <div class="layout-overlay layout-menu-toggle"></div>

                {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
                <div class="drag-target"></div>
            </div>

            {/* <!-- Core JS --> */}
            {/* <!-- build:js assets/vendor/js/core.js --> */}
            {/* <script src="../../assets/vendor/libs/jquery/jquery.js"></script>
            <script src="../../assets/vendor/libs/popper/popper.js"></script>
            <script src="../../assets/vendor/js/bootstrap.js"></script>
            <script src="../../assets/vendor/libs/node-waves/node-waves.js"></script>
            <script src="../../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
            <script src="../../assets/vendor/libs/hammer/hammer.js"></script>
            <script src="../../assets/vendor/libs/i18n/i18n.js"></script>
            <script src="../../assets/vendor/libs/typeahead-js/typeahead.js"></script>
            <script src="../../assets/vendor/js/menu.js"></script> */}

            {/* <!-- endbuild --> */}

            {/* <!-- Vendors JS --> */}
            {/* <script src="../../assets/vendor/libs/apex-charts/apexcharts.js"></script>
            <script src="../../assets/vendor/libs/swiper/swiper.js"></script>
            <script src="../../assets/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script> */}

            {/* <!-- Main JS --> */}
            {/* <script src="../../assets/js/main.js"></script> */}

            {/* <!-- Page JS --> */}
            {/* <script src="../../assets/js/dashboards-analytics.js"></script>
            <script src="../../assets/js/app-ecommerce-dashboard.js"></script> */}
        </>
    );
}