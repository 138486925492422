import React from 'react';

import QuickLinkComponent from './QuickLinkComponent.js';
import NotificationComponent from './NotificationComponent.js';
import UserMenuComponent from './UserMenuComponent.js';

export default function TopMenuComponent() {
    return (
        <>
           <nav
                class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                id="layout-navbar">
                <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <a class="nav-item nav-link px-0 me-xl-4" href="#!">
                        <i class="ti ti-menu-2 ti-md"></i>
                    </a>
                </div>
                <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    {/* <!-- Search --> */}
                    <div class="navbar-nav align-items-center">
                        <div class="nav-item navbar-search-wrapper mb-0">
                            <a class="nav-item nav-link search-toggler d-flex align-items-center px-0" href=" #!">
                                <i class="ti ti-search ti-md me-2 me-lg-4 ti-lg"></i>
                                <span class="d-none d-md-inline-block text-muted fw-normal">Search (Ctrl+/)</span>
                            </a>
                        </div>
                    </div>
                    {/* <!-- /Search --> */}

                    <ul class="navbar-nav flex-row align-items-center ms-auto">
                        {/* <!-- Style Switcher --> */}
                        <li class="nav-item dropdown-style-switcher dropdown">
                            <a
                                class="nav-link btn btn-text-secondary btn-icon rounded-pill dropdown-toggle hide-arrow"
                                href=" #!"
                                data-bs-toggle="dropdown">
                                <i class="ti ti-md"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-styles">
                                <li>
                                    <a class="dropdown-item" href=" #!" data-theme="light">
                                        <span class="align-middle"><i class="ti ti-sun ti-md me-3"></i>Light</span>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href=" #!" data-theme="dark">
                                        <span class="align-middle"><i class="ti ti-moon-stars ti-md me-3"></i>Dark</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        {/* <!-- / Style Switcher--> */}
                                
                        {/* <!-- Quick links --> */}
                        <QuickLinkComponent/>
                        {/* <!-- Quick links --> */}

                        {/* <!-- Notification --> */}
                        <NotificationComponent/>
                        {/* <!--/ Notification --> */}

                        {/* <!-- User --> */}
                        <UserMenuComponent/>
                        {/* <!--/ User --> */}
                    </ul>
                </div>

                {/* <!-- Search Small Screens --> */}
                <div class="navbar-search-wrapper search-input-wrapper d-none">
                    <input
                        type="text"
                        class="form-control search-input container-xxl border-0"
                        placeholder="Search..."
                        aria-label="Search..." />
                    <i class="ti ti-x search-toggler cursor-pointer"></i>
                </div>
            </nav>
        </>
    );
}