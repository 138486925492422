import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css'
import VerticalMenuComponent from '../../../components/VerticalMenuComponent';
import TopMenuComponent from '../../../components/TopMenuComponent';

export default function UserList() {
    return(
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <VerticalMenuComponent/>
                    <div class="layout-page">
                        <TopMenuComponent/>
                        <div style={{margin: '50px'}}>this is User List Page</div>
                    </div>
                </div>

                {/* <!-- Overlay --> */}
                <div class="layout-overlay layout-menu-toggle"></div>

                {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
                <div class="drag-target"></div>
            </div>
        </>
    );
}
