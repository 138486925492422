import React from 'react';
import { Link, Navigate } from 'react-router-dom';

export default function VerticalMenuComponent() {
    return (
        <>
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                <div className="app-brand demo">
                    <Link to="/dashboard" className="app-brand-link">
                        <img src="../assets/workx-logo.png" alt="WorkX Logo" width="175"/>
                    </Link>
                    <Link to="#!" className="layout-menu-toggle menu-link text-large ms-auto">
                        <i className="ti menu-toggle-icon d-none d-xl-block align-middle"></i>
                        <i className="ti ti-x d-block d-xl-none ti-md align-middle"></i>
                    </Link>
                </div>

                <div className="menu-inner-shadow"></div>

                <ul className="menu-inner py-1">
                    <li className="menu-item">
                        <Link to="/dashboard" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-smart-home"></i>
                            <div data-i18n="Dashboard">Dashboard</div>
                        </Link>
                    </li>

                    {/* <!-- leads menu start --> */}
                    <li className="menu-item">
                        <Link to="#!" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons ti ti-users"></i>
                            <div data-i18n="Leads">Leads</div>
                        </Link>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="/contacts" className="menu-link">
                                    <div data-i18n="Contacts">Contacts</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/leads" className="menu-link">
                                    <div data-i18n="Leads">Leads</div>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    {/* <!-- leads menu end --> */}

                    {/* <!-- Customers menu start --> */}
                    <li className="menu-item">
                        <Link to="#!" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons ti ti-users"></i>
                            <div data-i18n="Customers">Customers</div>
                        </Link>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="/all-customers" className="menu-link">
                                    <div data-i18n="All Customers">All Customers</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/sites" className="menu-link">
                                    <div data-i18n="Sites">Sites</div>
                                </Link>
                            </li>                  
                        </ul>
                    </li>
                    {/* <!-- Customers menu end --> */}
        
                    {/* <!-- Quote start --> */}
                    <li className="menu-item">
                        <a href="/quote" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-calendar"></i>
                            <div data-i18n="Quote">Quote</div>
                        </a>
                    </li>
                    {/* <!-- Quote menu end --> */}

                    {/* <!-- Job Scheduling menu start --> */}
                    <li className="menu-item">
                        <Link to="#!" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons ti ti-calendar"></i>
                            <div data-i18n="Job Scheduling">Job Scheduling</div>
                        </Link>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="/job-calendar" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-calendar"></i>
                                    <div data-i18n="Job Calendar">Job Calendar</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/job-kanban" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-layout-kanban"></i>
                                    <div data-i18n="Job Kanban">Job Kanban </div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/field-dashboard" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-calendar"></i>
                                    <div data-i18n="Field Dashboard">Field Dashboard</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/field-tracking" className="menu-link">
                                    <i className="menu-icon tf-icons ti ti-calendar"></i>
                                    <div data-i18n="Field Tracking">Field Tracking</div>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    {/* <!-- Job Scheduling menu end --> */}
        
                    {/* <!-- inventory menu start --> */}
                    <li className="menu-item">
                        <Link className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons ti ti-shopping-cart"></i>
                            <div data-i18n="Inventory">Inventory</div>
                        </Link>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="/product-list" className="menu-link">
                                    <div data-i18n="Product List">Product List</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/add-product" className="menu-link">
                                    <div data-i18n="Add Product">Add Product</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/category-list" className="menu-link">
                                    <div data-i18n="Category List">Category List</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/store-list" className="menu-link">
                                    <div data-i18n="Store List">Store List</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/store-details" className="menu-link">
                                    <div data-i18n="Store Details">Store Details</div>
                                </Link>
                            </li>
                        </ul>       
                    </li>
                    {/* <!-- inventory menu end --> */}
        
                    {/* <!-- payments list start  --> */}
                    <li className="menu-item">
                        <Link to="/payments-recieved" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-file-dollar"></i>
                            <div data-i18n="Payments">Payments</div>
                        </Link>
                    </li>
                    {/* <!-- /payment lists end --> */}
            
                    {/* <!-- Misc title --> */}
                    <li className="menu-header small">
                        <span className="menu-header-text" data-i18n="Misc">Misc</span>
                    </li>
                    {/* <!-- Misc title--> */}

                    {/* <!-- Users and Roles start --> */}
                    <li className="menu-item">
                        <Link to="#!" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons ti ti-users"></i>
                            <div data-i18n="Users & Roles">Users & Roles</div>
                        </Link>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="/user-list" className="menu-link">
                                    <div data-i18n="Users List">Users List</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/roles" className="menu-link">
                                    <div data-i18n="Roles">Roles</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/teams" className="menu-link">
                                    <div data-i18n="Teams">Teams</div>
                                </Link>
                            </li>           
                        </ul>
                    </li>
                    {/* <!-- Users and Roles end --> */}

                    {/* <!-- Settings start --> */}
                    <li className="menu-item">
                        <Link to="/settings" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-settings"></i>
                            <div data-i18n="Settings">Settings</div>
                        </Link>
                    </li>
                    {/* <!-- Settings end --> */}
                </ul>
            </aside>
        </>
    );
}